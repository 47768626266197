<template>
  <span>
    <v-btn
      color="primary"
      class="font-weight-black"
      @click="downloadApp"
    >
      Télécharger l'application
    </v-btn>

    <v-dialog
      v-model="isDialogOpenForDesktop"
      max-width="410"
    >
      <template #default>
        <v-card rounded="lg">
          <v-card-text class="pa-4">
            <h4 class="mb-3">Télécharger l'application :</h4>
            <div class="d-flex align-center justify-space-between">
              <nuxt-link
                :href="LINK_APP_IOS"
                target="_blank"
                class="d-inline-block"
              >
                <img
                  src="~/assets/images/landing-pages/apple-store.png"
                  :height="HEIGHT"
                  class="d-block"
                />
              </nuxt-link>
              <nuxt-link
                :href="LINK_APP_ANDROID"
                target="_blank"
                class="d-inline-block"
              >
                <img
                  src="~/assets/images/landing-pages/google-play-store.png"
                  :height="HEIGHT"
                  class="d-block"
                />
              </nuxt-link>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </span>
</template>

<script setup lang="ts">
const { isDesktop, isApple, isAndroid } = useDevice()

const isDialogOpenForDesktop = ref(false)

const HEIGHT = 60

// TODO: replace links for fidmarques app by salsa app
const LINK_APP_IOS = 'https://apps.apple.com/app/salsa-reduc/id6677049184'
const LINK_APP_ANDROID =
  'https://play.google.com/store/apps/details?id=com.purchease.salsa&hl=fr'

function downloadApp() {
  if (isDesktop) {
    isDialogOpenForDesktop.value = true
    return
  }
  if (isApple) {
    window.location.href = LINK_APP_IOS
    return
  }
  if (isAndroid) {
    window.location.href = LINK_APP_ANDROID
    return
  }

  isDialogOpenForDesktop.value = true
}
</script>
